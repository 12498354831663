import $ from 'jquery';

function handleNav() {
  $('.navbar .toggle').on('click', () => {
    $('.navbar').toggleClass('is-menu-open');
    $('.navbar .btn').addClass('secondary');
  });

  $('.navbar a').on('click', () => {
    $('.navbar').removeClass('is-menu-open');
  });

  $(window).on('resize', () => {
    if ($(window).width() >= 1024) {
      $('.navbar').removeClass('is-menu-open');
      $('.navbar .btn').removeClass('secondary');
    }
  });

  // document.querySelectorAll('footer a:not([target="_blank"])').forEach((link) => {
  //   link.classList.add('empty-btn');
  // });
}

function teamPortfolioResizeDesktop() {
  const portfolios = document.querySelectorAll('section.hero .swiper-slide .portfolio ul');

  portfolios.forEach((portfolio) => {
    const children = portfolio.getElementsByClassName('company');
    if (children.length > 5) {
      portfolio.classList.add('big');
    }
  });
}

function teamPortfolioResizeMobile() {
  const portfolios = document.querySelectorAll('section.hero .swiper.mobile .swiper-slide .portfolio ul');

  portfolios.forEach((portfolio) => {
    const children = portfolio.getElementsByClassName('company');
    if (children.length > 3) {
      portfolio.style.maxHeight = '5.5rem';

      const viewAll = document.createElement('div');
      viewAll.classList.add('view-all');
      viewAll.innerHTML = 'View All';

      portfolio.parentElement.appendChild(viewAll);

      viewAll.addEventListener('click', () => {
        document.querySelector('body').classList.add('portfolio-big');
        portfolio.style.maxHeight = '';
      });

      const backButton = document.querySelectorAll('.swiper-slide .back');

      backButton.forEach((button) => {
        button.addEventListener('click', () => {
          document.querySelector('body').classList.remove('portfolio-big');
          portfolio.style.maxHeight = '5.5rem';
        });
      });
    }
  });
}

function checkForVideo() {
  const cards = document.querySelectorAll('.row > div .video');
  cards.forEach((card) => {
    card.parentElement.parentElement.classList.add('video');
  });
}

function videoControls() {
  const controls = document.querySelectorAll('.video .controls');

  controls.forEach((control) => {
    control.addEventListener('click', () => {
      const video = control.parentElement.querySelector('video');
      const parent = control.parentElement.parentElement.parentElement;
      console.log(parent);

      if (video.paused) {
        video.play();
        control.style.opacity = 0;
        parent.classList.add('hover');
      } else {
        video.pause();
        control.style.opacity = 1;
        parent.classList.remove('hover');
      }
    });
  });
}

export { handleNav, teamPortfolioResizeDesktop, teamPortfolioResizeMobile, checkForVideo, videoControls };
